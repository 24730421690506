<template>
  <div
    class="f-slider-wrapper"
    :class="{
      'has-error': error,
      'is-complete': isComplete,
    }"
  >
    <div class="slider-wrapper d-flex align-items-center">
      <div class="slider-circle" :style="`left: ${value}%`">
        <AppIcon name="arrow-bracket" class="rotate-90" size="24px" />
      </div>
      <div class="filled-background" :style="`width: ${value}%`" />

      <div class="flex-center flex-column align-items-start" style="padding-left: 70px;">
        <AppText class="font-medium" style="margin-bottom: 1px;">
          {{ title }}
        </AppText>
        <AppText size="12px" color="var(--color-black-04)">
          {{ subtitle }}
        </AppText>
      </div>

      <div class="complete-message">
        <AppText color="var(--color-primary)" class="font-medium">
          {{ t('common.complete') }}
        </AppText>
      </div>
    </div>

    <ElSlider
      v-if="!error"
      v-model="value"
      class="f-slider"
      :show-tooltip="false"
      :max="81"
      v-bind="$attrs"
      @change="onSliderChange"
    />
  </div>
</template>

<script>
import 'element-plus/theme-chalk/el-slider.css';
import { ElSlider } from 'element-plus';
import {
  computed, defineComponent, ref, watch,
} from 'vue';

export default defineComponent({
  name: 'FSlider',
  components: {
    ElSlider,
  },
  inheritAttrs: false,
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Slide to confirm',
    },
    subtitle: {
      type: String,
      default: 'This action is irreversible.',
    },
  },
  emits: ['complete'],
  setup(props, { emit }) {
    const MIN_VALUE = 2;
    const MAX_VALUE = 81;

    const value = ref(MIN_VALUE);
    const wasSent = ref(false);

    const isComplete = ref(false);

    const complete = async ({ time } = { time: 300 }) => {
      isComplete.value = true;
      await new Promise((resolve) => { setTimeout(resolve, time); });
    };

    const reset = () => {
      value.value = MIN_VALUE;
      isComplete.value = false;
    };

    watch(computed(() => props.error), () => {
      if (props.error) {
        reset();
      }
    });

    watch(value, (newVal, oldVal) => {
      if (newVal < MIN_VALUE) {
        value.value = MIN_VALUE;
      }
      if (newVal === 0 && oldVal === MAX_VALUE) {
        value.value = MAX_VALUE;
      }
      if (value.value === MAX_VALUE) {
        emit('complete');
        wasSent.value = true;
      }
    });

    const onSliderChange = () => {
      if (value.value < MAX_VALUE) {
        reset();
      }
    };

    const opacityColor = computed(() => (value.value * 1.23456) / 100);

    return {
      value,
      opacityColor,

      isComplete,

      onSliderChange,

      complete,
      reset,
    };
  },
});
</script>

<style scoped lang="scss">
.f-slider-wrapper {
  position: relative;
  width: 300px;
  background-color: var(--color-black-005);

  border-radius: 28px;
  overflow: hidden;
  &.is-complete {
    .complete-message {
      opacity: 1;
    }
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-primary-02);
    opacity: v-bind(opacityColor);
  }
}

.slider-wrapper {
  width: 100%;
  height: 60px;
}

.slider-circle {
  background-color: var(--color-white);
  border-radius: 50%;
  height: 52px;
  width: 52px;
  box-shadow: 0px 2px 5px var(--color-black-01);
  position: absolute;
  top: 4px;
  z-index: 4;

  @include flex-center;

  opacity: 1;
  @include transition-base(opacity);
}

.filled-background {
  height: 52px;
  width: 0;

  background-color: #f2f2f2;

  position: absolute;
  z-index: 2;
  left: 30px;
}

.f-slider {
  position: absolute;
  z-index: 4;
  top: 0;
  width: 82%;
  margin-left: 8%;
  margin-top: 2px;
  opacity: 0;
  --el-slider-button-size: 52px;
  --el-slider-height: 0px;

  :deep(.el-slider__button) {
    cursor: pointer;
  }
  :deep(.el-slider__button-wrapper) {
    cursor: pointer;
  }
}

.complete-message {
  position: absolute;
  z-index: 5;
  top: 21px;
  opacity: 0;
  pointer-events: none;

  width: 100%;
  text-align: center;

  @include transition-base(opacity, 0.1s)
}
</style>
