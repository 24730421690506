<template>
  <div
    class="f-radio"
    :class="{
      'is-full-width': isFullWidth,
      [color]: color,
    }"
  >
    <el-radio-group
      :model-value="modelValue"
      v-bind="$attrs"
      @change="onModelValueUpdate"
    >
      <el-radio
        v-for="option in options"
        :key="option.value"
        :label="option?.value"
      >
        {{ option.text }}
      </el-radio>
    </el-radio-group>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import 'element-plus/theme-chalk/el-radio-group.css';
import 'element-plus/theme-chalk/el-radio.css';
import { ElRadio, ElRadioGroup } from 'element-plus';

export default defineComponent({
  name: 'FRadio',
  components: {
    ElRadioGroup,
    ElRadio,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const onModelValueUpdate = (newValue) => {
      emit('update:modelValue', newValue);
    };

    return {
      onModelValueUpdate,
    };
  },
});
</script>

<style scoped lang="scss">
.f-radio {
  :deep(.el-radio) {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }

    &:focus {
      .el-radio__input .el-radio__inner {
          border: 1px solid var(--color-primary);
          outline: none;
          box-shadow: none;
        }
    }

    .el-radio__inner {
      background: var(--color-white);
      border-color: #E6E6E6;
      color: var(--color-black);
      width: 20px;
      height: 20px;

      &:hover {
        border: 1px solid var(--color-primary);
      }
    }
    &.is-checked {
      .el-radio__inner {
        // background: var(--color-primary);
        background: var(--color-white);
        &::after {
          background: var(--color-primary);
          width: 12px;
          height: 12px;
        }
      }
      .el-radio__label {
        color: var(--color-black);
      }
    }
  }
}
</style>
