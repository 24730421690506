<template>
  <div
    class="f-input"
    :class="{
      'is-full-width': isFullWidth,
      'no-margin': noMargin,
      'has-prefix': hasPrefix,
    }"
  >
    <AppText
      v-if="label"
      class="f-input__label font-medium"
      :class="{ 'visually-hidden': isHideLabelVisually }"
    >
      {{ label }}
    </AppText>
    <div class="f-input__container">
      <!-- title="" it's hack to disabled native browser tooltip -->
      <ElInput
        ref="inputElement"
        v-model="value"
        v-maskito="maskitoOptions"
        v-bind="$attrs"
        :class="{
          'has-value': hasValue,
          'has-error': hasErrorClass,
          'has-success': hasSuccess,
          'is-filled': isFilled,
          ...customClasses,
        }"
        :size="customSize"
        :rows="textareaRows"
        :type="type"
        :maxlength="maxlength"
        :placeholder="placeholder.toString() || ''"
        title=""
        @blur="onBlur"
      >
        <template v-for="(el, slot) of $slots" #[slot]="scope">
          <slot :name="slot" v-bind="scope" />
        </template>
      </ElInput>

      <!--START hack for properly working browser autofill -->
      <input v-if="isAutofillEnable" :aria-label="t('ariaLabel.password')" class="visually-hidden" type="password">
      <!--END hack for properly working browser autofill -->

      <div class="slot-append" @click="onSlotApendClick">
        <slot name="append" />
        <AppIcon
          v-if="showValidateIcon && modelValue?.length"
          :key="hasError"
          :name="hasError ? 'red-close' : 'green-checked'"
        />
        <AppIcon
          v-else-if="showErrorIcon"
          name="x-close"
          fill="var(--color-error)"
        />
      </div>
    </div>
    <AppText v-if="(errorText && hasError) || validationErrorMessage" class="f-input__error-text">
      {{ validationErrorMessage || errorText }}
    </AppText>
    <AppText v-if="!((errorText && hasError) || validationErrorMessage) && (hint || $slots.hint)" class="f-input__hint">
      <slot v-if="$slots.hint" name="hint" />
      <template v-else>
        {{ hint }}
      </template>
    </AppText>
  </div>
</template>

<script>
import 'element-plus/theme-chalk/el-input.css';
import { ElInput } from 'element-plus';
import { computed, defineComponent, onMounted } from 'vue';
import { maskito } from '@maskito/vue';

import { maskitoRules } from '@/common/data';
import { useAutoFocus } from '@/composables/useAutoFocus';
import { useModel } from '@/composables/useModel';
import { useTabNavigation } from '@/composables/useTabNavigation';

export default defineComponent({
  name: 'FInput',
  components: {
    ElInput,
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    hasSuccess: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    textareaRows: {
      type: [Number, String],
      default: 2,
    },
    showValidateIcon: {
      type: Boolean,
      default: false,
    },
    showErrorIcon: {
      type: Boolean,
      default: false,
    },
    hasForceError: {
      type: Boolean,
      default: false,
    },
    isFullWidth: {
      type: Boolean,
      default: true,
    },
    isFilled: {
      type: Boolean,
      default: false,
    },
    noMargin: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'string',
    },
    maxlength: {
      type: [String, Number],
      default: 200,
    },
    isInheritAttrs: {
      type: Boolean,
      default: true,
    },
    isHideLabelVisually: {
      type: Boolean,
      default: false,
    },
    isAutofillEnable: {
      type: Boolean,
      default: false,
    },
    validationError: {
      type: Object,
      default: () => {},
    },
    hasPrefix: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: [String, Function, Object],
      default: () => ({}),
    },
    size: {
      type: String,
      default: '',
    },
    placeholder: {
      type: [String, Number],
      default: '',
    },
  },
  emits: ['update:modelValue', 'slot-append-click'],
  setup(props, { emit, attrs }) {
    const { element: inputElement } = useTabNavigation();
    const { value } = useModel(props, emit);

    const hasValue = computed(() => Boolean(props.modelValue?.length || props.modelValue));

    onMounted(() => {
      if (attrs.autofocus || attrs.autofocus === '') {
        useAutoFocus(inputElement.value.input);
      }
    });

    const validationErrorMessage = computed(() => props.validationError?.message);

    const hasErrorClass = computed(() => (
      props.hasError && props.hasForceError)
      || (props.hasError && props.modelValue?.length)
      || validationErrorMessage.value);

    const onSlotApendClick = () => {
      emit('slot-append-click');
    };

    const onBlur = () => {
      const selectionText = window.getSelection();
      if (selectionText) {
        window.getSelection().removeAllRanges();
      }
    };

    const maskitoOptions = computed(() => {
      if (typeof props.mask !== 'string') {
        return props.mask || {};
      }

      return maskitoRules[props.mask] || {};
    });

    const customSizes = ['medium', 'big', 'big-medium'];
    const customSize = computed(() => (customSizes.includes(props.size) ? null : props.size));

    const customClasses = computed(() => ({
      [`el-input--${props.size}`]: customSize.value === null,
    }));

    return {
      value,
      maskitoOptions,

      hasValue,

      validationErrorMessage,
      hasErrorClass,

      customSize,
      customClasses,

      onSlotApendClick,
      onBlur,

      inputElement,
    };
  },
  beforeCreate() {
    this.$options.inheritAttrs = this.isInheritAttrs;
    this.$options.directives = this.type !== 'textarea' ? { maskito } : { maskito: {} };
  },
});
</script>

<style scoped lang="scss">
@import "styles";

$primary: map-get($colors, "primary");
// $error: map-get($colors, "error");
$error: #C25555;
$success: #50A621;

.f-input{
  &__error-text {
    min-height: 25px;
    padding-top: 8px;
    display: block;
    font-size: 13px;
    color: $error;
    white-space: pre-line;
  }
  &__hint {
    min-height: 25px;
    padding-top: 8px;
    display: block;
    font-size: 13px;
    color: var(--color-black-04);
    white-space: pre-line;
  }
  &__container {
    display: flex;
    position: relative;
    .slot-append {
      position: absolute;
      right: 18px;
      height: 100%;

      @include flex-center;
    }
  }

  &.has-prefix {
    :deep(.el-input__prefix) {
      left: 4px;
    }
  }
}

:deep(.el-input .el-input__wrapper), :deep(.el-textarea__inner) {
  box-shadow: unset;
  border: 1px solid var(--el-input-border-color);

  @include transition-base(border);
  &:hover {
    border: 1px solid var(--el-input-hover-border-color);
  }

  &.is-focus, &:focus {
    border: 1px solid var(--el-input-focus-border-color);
  }
}

:deep(.el-input) {
  &.is-disabled {
    cursor: not-allowed !important;
    & > * {
      cursor: not-allowed !important;
    }
    .el-input__wrapper, .el-textarea__inner {
      border: 1px solid var(--color-E6E6E6);
    }
  }
  &.el-input-group--append > .el-input__wrapper {
    border-radius: var(--el-input-border-radius);
  }
  .el-input__wrapper {
    padding: 0 16px 0 12px;
  }
  .el-input__inner {
    cursor: pointer;
    padding: 0 4px !important;
    @include form-element;

    &:autofill  {
      box-shadow: 0 0 0px 1000px #fff inset;
    }
  }
  .el-input__prefix-inner>:last-child {
    margin-right: 0 !important;
  }
  .el-input__prefix {
    margin-right: 8px;
  }
  .el-input__prefix,
  .el-input__suffix {
    justify-content: center;
    align-items: center;
    .el-input__icon {
      display: none;
    }
  }
  .el-input__suffix {
    &-inner>:first-child {
      margin-left: 8px !important;
    }
  }

  &.is-disabled {
    .el-input__prefix-inner {
      .icon path {
        fill: var(--color-black-02);
      }
    }
    .el-input__inner {
      pointer-events: none;
      user-select: none;
    }
  }

  .el-input__wrapper, .el-input__inner {
    @include height-regular;
  }

  &.el-input--small {
    .el-input__wrapper, .el-input__inner {
      @include height-small;
    }
  }

  &.el-input--medium {
    .el-input__wrapper{
      min-height: 48px;
      height: 48px;
    }

    .el-input__inner {
      min-height: 46px;
      height: 46px;
    }
  }

  &.el-input--big {
    .el-input__wrapper, .el-input__inner {
      min-height: 52px;
      height: 52px;
      font-size: 15px;
    }

    .el-input__suffix {
      right: 11px;
    }
  }

  &.el-input--big-medium {
    .el-input__wrapper, .el-input__inner {
      min-height: 60px;
      height: 60px;
      font-size: 20px;
    }
  }

  &.el-input--large {
    .el-input__wrapper, .el-input__inner {
      min-height: 80px;
      height: 80px;
    }
  }
}

:deep(.el-textarea) {
  .el-textarea__inner {
    min-height: 80px !important;
    padding: 12px 16px;
    resize: none;
    color: var(--color-black);
    font-size: 13px;

    &::placeholder {
      opacity: 1;
      color: var(--color-999999);
    }
  }
}

.has-value:deep(.el-input__inner) {
  &[type=password] {
    font-size: 25px !important;
    letter-spacing: 0.1em !important;
  }
}

.has-error {
  :deep(.el-input__inner, .el-textarea__inner) {
    border-color: $error;
    color: $error;
  }

  &.has-value {
    :deep(.el-input__prefix-inner) {
      .icon path {
        fill: $error;
      }
    }
  }
}

.has-success:deep(.el-input__inner) {
  border-color: $success
}

.is-full-width {
  width: 100%;
}

.no-margin {
  margin-bottom: 0 !important;
}

:deep(.el-input-group__append) {
  display: none;
}

// :deep(.el-input__wrapper:has(.el-input__inner:focus-visible)) {
//   @include focus-visible-style;
//   border-color: var(--el-input-border-color) !important;
// }

:deep(.el-textarea.is-exceed .el-textarea__inner) {
  box-shadow: unset;
}
</style>
