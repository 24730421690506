<template>
  <!-- Not an ElementUI component -->
  <img
    v-bind="$attrs"
    :src="imageSrc"
    class="image"
    alt="image"
    :class="{ 'has-data': imageSrc }"
    draggable="false"
  >
</template>

<script>
import { defineComponent, onBeforeMount } from 'vue';

import { useImage } from '@/composables/useImage';

export default defineComponent({
  name: 'FImage',
  props: {
    src: {
      type: String,
      default: '',
    },
    isReadyPicture: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update'],
  setup(props, { emit }) {
    const {
      imageSrc,
      getImageUrl,
    } = useImage();

    onBeforeMount(async () => {
      if (props.isReadyPicture) {
        imageSrc.value = props.src;
      } else {
        await getImageUrl(props.src);
      }
      emit('update', imageSrc.value);
    });

    return {
      imageSrc,
    };
  },
});
</script>

<style lang="scss" scoped>
.image {
  opacity: 0;
  @include transition-base(opacity);
  &.has-data {
    opacity: 1;
  }
}

</style>
