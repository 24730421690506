<template>
  <div
    class="f-select--wrapper"
    :class="{
      'has-label-on-right': hasLabelOnRight,
      'no-margin': noMargin,
      'has-label': Boolean(label),
    }"
  >
    <AppText v-if="label" class="f-input__label font-medium">
      {{ label }}
    </AppText>
    <ElSelect
      v-bind="$attrs"
      ref="selectElement"
      class="f-select"
      :class="{
        'has-arrow': hasArrow,
        'is-big': isBig,
        'is-filled': isFilled,
        'has-error': hasError,
        'is-focused-by-keyboard': isFocusedByKeyboard,
        'has-close-icon': hasCloseIcon && $attrs.modelValue,
        ...customClasses,
      }"
      :size="customSize"
      :popper-class="`dropdown-popper ${popperClass}`"
      :placeholder="placeholder || label"
      :filter-method="onFilter"
      :no-data-text="noDataText"
      :suffix-icon="suffixIcon"
      @change="onChange"
      @blur="onBlur"
      @keypress.space="onSpacePress"
    >
      <template v-for="(el, slot) of $slots" #[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
      <CustomScroll :key="filteredOptions" :style="{ 'max-height': maxHeight }">
        <ElOption
          v-for="option in filteredOptions"
          :key="option[optionValue]"
          :class="['f-select-option', colorClassName]"
          :label="optionLabel || option[optionText]"
          :value="option[optionValue]"
          :disabled="Boolean(option[disabledField])"
        >
          <slot name="option" :item="option">
            <template v-if="showObject">
              {{ option[optionText] }}
              <br>
              {{ option[optionValue] }}
            </template>
          </slot>
        </ElOption>
      </CustomScroll>
    </ElSelect>
    <AppText v-if="hasError" class="f-select__error-text">
      {{ errorText }}
    </AppText>
  </div>
</template>

<script>
import 'element-plus/theme-chalk/el-select.css';
import { ElOption, ElSelect } from 'element-plus';
import {
  computed, defineComponent, h, ref, shallowRef,
} from 'vue';

import AppIcon from '@/components/Icon/AppIcon.vue';

import { useTabNavigation } from '@/composables/useTabNavigation';

export default defineComponent({
  name: 'FSelect',
  components: {
    ElSelect,
    ElOption,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default() {
        return [{
          text: 'Option1',
          value: '1',
        }];
      },
    },
    hasArrow: {
      type: Boolean,
      default: false,
    },
    hasCloseIcon: {
      type: Boolean,
      default: false,
    },
    hasLabelOnRight: {
      type: Boolean,
      default: false,
    },
    isFilled: {
      type: Boolean,
      default: false,
    },
    isBig: {
      type: Boolean,
      default: false,
    },
    showObject: {
      type: Boolean,
      default: false,
    },
    noMargin: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'default',
      validator(value) {
        return ['primary', 'default', ''].includes(value);
      },
    },
    returnKey: {
      type: String,
      default: '',
    },
    optionText: {
      type: String,
      default: 'text',
    },
    disabledField: {
      type: String,
      default: '',
    },
    optionValue: {
      type: String,
      default: 'value',
    },
    popperClass: {
      type: String,
      default: 'select-container',
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
      default: '',
    },
    optionLabel: {
      type: String,
      default: null,
    },
    noDataText: {
      type: String,
      default: 'No data',
    },
    size: {
      type: String,
      default: '',
    },
    maxHeight: {
      type: String,
      default: '220px',
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const colorClassName = `color-${props.color}`;

    const suffixIcon = shallowRef({
      render() {
        return h(AppIcon, {
          name: 'arrow-dropdown',
          size: '18px',
        });
      },
    });

    const onChange = (val) => {
      const returnValue = props.returnKey ? props.options.find((el) => el[props.optionValue] === val)[props.returnKey] : val;
      emit('update:modelValue', returnValue);
    };

    const searchInput = ref('');
    const onFilter = (val) => {
      searchInput.value = val;
    };

    const { element: selectElement, isFocusedByKeyboard } = useTabNavigation();
    const onBlur = () => {
      isFocusedByKeyboard.value = false;
      setTimeout(() => {
        searchInput.value = '';
      }, 300);
    };

    const filteredOptions = computed(() => {
      if (!searchInput.value) {
        return props.options;
      }

      const inputText = searchInput.value.toLowerCase();
      return props.options.filter((el) => {
        const hasTextMatch = el[props.optionText].toLowerCase().includes(inputText);
        const hasTimeMatch = el?.time?.toLowerCase().includes(inputText);
        const hasValueMatch = (typeof el[props.optionValue] === 'string')
          ? el[props.optionValue].toLowerCase().includes(inputText)
          : false;

        const hasUnderlinedTextMatch = el[props.optionText].toLowerCase().replace('_', ' ').includes(inputText);

        return hasTextMatch || hasTimeMatch || hasValueMatch || hasUnderlinedTextMatch;
      });
    });

    const customSizes = ['x-small'];
    const customSize = computed(() => (customSizes.includes(props.size) ? null : props.size));

    const customClasses = computed(() => ({
      [`el-select--${props.size}`]: customSize.value === null,
    }));

    const onSpacePress = (e) => {
      e.preventDefault();
    };

    return {
      suffixIcon,
      colorClassName,
      onChange,
      onFilter,
      onBlur,
      filteredOptions,

      onSpacePress,

      customSize,
      customClasses,

      selectElement,
      isFocusedByKeyboard,
    };
  },
});
</script>

<style scoped lang="scss">
@import "styles";

$primary: map-get($colors, "primary");
$error: #C25555;

:global(.el-select__popper) {
  transform: translateY(-8px);
}

.f-select {
  &--wrapper {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    @include margin-bottom;
    width: 100%;

    :deep(.el-input__wrapper) {
      padding: 0 12px;

      background: #fff;
      @include transition-base(background);
    }
  }
  &__error-text {
    display: block;
    margin-top: 0.4rem;
    color: $error;
  }
}
.has-label-on-right {
  flex-direction: row;
  align-items: center;
  .f-input__label {
    padding-bottom: 0;
    margin-right: 4rem;
    margin-bottom: 0;
    min-width: 80px;
    font-size: 15px;
    @include font-medium;
  }
}

.el-select {
  &:deep(.select-trigger .el-input__inner) {
    height: 44px;
    padding: 0 !important;
    @include height;
    @include form-element;
  }
  &:deep(.el-input.is-focus .el-input__inner),
  &:deep(.el-input .el-input__inner:focus) {
    border-color: $primary;
  }
  &:deep(.el-input__prefix) {
    margin-right: 8px;
  }
  :deep(.el-input__prefix-inner>:last-child) {
    margin-right: 0px;
  }
  &:deep(.el-input__suffix) {
    width: 18px;
    display: none;
    .el-input__suffix-inner {
      width: 18px;
      pointer-events: none;

      :first-child {
        margin-left: 0;
      }
    }
  }
  &.has-arrow:deep(.el-input__suffix) {
    display: inline-flex;
  }
  &.has-close-icon.has-arrow:deep(.el-input__suffix) {
    display: none;
  }

  &:deep(.el-input .el-input__inner) {
    pointer-events: none;
    margin-left: 4px;
  }

  &.is-filled:deep(.el-input__inner) {
    background: #F2F2F2;
    &::placeholder {
      opacity: 1;
      color: #333;
    }
  }

  &.is-filled:deep(.is-focus .el-input__inner) {
    &::placeholder {
      opacity: 1;
      color: #333;
    }
  }

  &.has-error:deep(.el-input__wrapper) {
    box-shadow: none;
    border: 1px solid $error;
  }

  :deep(.el-input__wrapper) {
    box-shadow: none !important;
    border: 1px solid var(--color-black-01);
    @include transition-base(border);

    &:hover {
      border: 1px solid var(--color-black-02);
    }

    &.is-focus {
      box-shadow: none !important;
      border: 1px solid var(--color-primary);
    }
  }
  &:deep(.el-input.is-focus .el-input__wrapper) {
    box-shadow: none !important;
    border: 1px solid var(--color-primary);
  }
}

.f-select--wrapper.no-margin {
  margin-bottom: 0;
}

:deep(.el-input--small) {
  .el-input__wrapper {
    padding: 0 10px;
  }
  .el-input__inner {
    min-height: 36px !important;
  }
}

.el-select-dropdown__item {
  padding: 13px 20px;
  height: auto;
  line-height: 1.2;
  font-size: 13px;
  border-bottom: 1px solid #E6E6E6;
  background: #ffffff;
  &:first-child {
    border-top: 1px solid #E6E6E6;
  }
  &.selected {
    color: #555;
  }
}

.is-big {
  &:deep(.el-input__inner) {
    height: 64px;
    &::placeholder {
      font-size: 13px;
    }
  }
  &:deep(.el-input--prefix .el-input__inner) {
    padding-left: 56px !important;
  }
  &:deep(.el-input__prefix) {
    left: 20px;
  }
}

.color-default {
  &.selected, &.hover, &:hover {
    background: #f1f1f1;
  }
}
.color-primary {
  &.selected, &.hover, &:hover {
    background: #FBF3EC;
  }
}

// .f-select--close {
//   position: absolute;
//   z-index: 8;
//   top: 0px;
//   right: 0px;

//   width: 40px;
//   height: 40px;

//   border-top-right-radius: 3px;
//   border-bottom-right-radius: 3px;

//   @include flex-center;
//   // background: #e6e6e6;
//   cursor: pointer;

//   :deep(.icon) {
//     g {
//       opacity: 0.6;
//       @include transition-base(opacity);
//       path{
//         stroke: var(--color-black);
//       }
//     }
//   }
//   &:hover {
//     :deep(.icon) {
//       g {
//         opacity: 1;
//       }
//     }
//   }
// }

// .has-close-icon {
//   :deep(.el-input__wrapper) {
//     overflow: hidden;
//     &::before {
//       content: '';
//       width: 40px;
//       height: 40px;
//       background: #e6e6e6;
//       @include transition-base(background);
//       border-top-right-radius: 3px;
//       border-bottom-right-radius: 3px;

//       position: absolute;
//       top: 0;
//       right: 0;
//     }
//     .el-input__suffix {
//       display: none;
//     }
//   }
// }

.has-label {
  .f-select--close {
    top: 25px;
  }
}

:deep(.el-select.el-select--x-small) {
  .el-input__wrapper {
    padding: 0 8px 0 12px;
  }
  .el-input__wrapper, .select-trigger .el-input__inner {
    height: 36px;
    min-height: 36px;
  }
}

// Disabled
:deep(.el-select.el-select--disabled) {
  .el-input__wrapper {
    background-color: var(--el-disabled-bg-color);
    border: 1px solid var(--color-E6E6E6);
  }
  .el-input__inner {
    color: var(--color-black-02);
  }
  .el-input__suffix .icon path {
    fill: var(--color-black-02);
  }
}

// :focus-visible
:deep(.el-select.is-focused-by-keyboard) {
  @include focus-visible-style;
  .el-input__wrapper.is-focus {
    border-color: var(--el-input-border-color) !important;
  }
}

:global(.el-scrollbar__bar.is-vertical) {
  display: none !important;
}

:global(.el-select-dropdown__list) {
  max-height: v-bind(maxHeight);
  overflow: hidden;
}

:global(.el-select-dropdown__wrap) {
  max-height: v-bind(maxHeight);
}

:global(.el-select-dropdown .is-disabled) {
  color: rgba(0, 0, 0, 0.2);
  cursor: not-allowed !important;
  &:hover {
    background: initial;
  }
}
</style>
