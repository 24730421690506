<template>
  <div :class="transitionName.includes('fade-absolute') ? 'position-relative' : ''">
    <Transition :name="transitionName">
      <el-skeleton
        v-if="isLoading"
        :loading="isLoading"
        animated
        class="f-skeleton"
        :class="{
          [variant]: true,
          'is-centered': isCentered,
          'custom-height': Boolean(height),
        }"
        v-bind="$attrs"
      >
        <template #template>
          <el-skeleton-item
            :variant="variant"
            :class="{
              'is-full-width': isFullWidth,
              'custom-height': Boolean(height),
            }"
          />
        </template>
        <template #default />
      </el-skeleton>
      <slot v-else />
    </Transition>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import 'element-plus/theme-chalk/el-skeleton-item.css';
import 'element-plus/theme-chalk/el-skeleton.css';
import { ElSkeleton, ElSkeletonItem } from 'element-plus';

export default defineComponent({
  name: 'FSkeleton',
  components: {
    ElSkeleton,
    ElSkeletonItem,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    transitionName: {
      type: String,
      default: 'show-1',
    },
    variant: {
      type: String,
      default: 'p',
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    isCentered: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '',
    },
    circleSize: {
      type: String,
      default: '40px',
    },
  },
});
</script>

<style scoped lang="scss">
:deep(.el-skeleton__button) {
  &.is-full-width {
    width: 100%;
  }
}
:deep(.el-skeleton__h1) {
  height: 34px;
  width: 80%;
}
.f-skeleton.circle, :deep(.el-skeleton__circle) {
  width: v-bind('circleSize');
  height: v-bind('circleSize');
}

.custom-height {
  height: v-bind('height');
}

.is-centered {
  display: flex;
  justify-content: center;
}
</style>
