<template>
  <el-switch
    v-bind="$attrs"
    :loading="loading"
    class="f-switch"

    :class="{
      'is-small': isSmall,
      'is-big': isBig,
      'is-loading': loading,
      'is-intermidiate': isIntermidiate,
      [type]: true,
      'has-labels': labels && labels.length,
    }"
    inline-prompt
    :active-text="(!loading && labels && labels[0]) || ''"
    :inactive-text="(!loading && labels && labels[1]) || ''"
  >
    <template v-for="(el, slot) of $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </el-switch>
</template>

<script>
import { defineComponent } from 'vue';
import 'element-plus/theme-chalk/el-switch.css';
import { ElSwitch } from 'element-plus';

export default defineComponent({
  name: 'FSwitch',
  components: {
    ElSwitch,
  },
  props: {
    labels: {
      type: Array,
      default() {
        return ['', ''];
      },
    },
    isBig: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    isIntermidiate: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'primary',
      validator: (value) => ['primary', 'success', 'danger'].includes(value),
    },
  },
  setup() {
  },
});
</script>

<style scoped lang="scss">
.f-switch {
  :deep(.el-switch__core) {
    background-color: var(--color-white);
    border: 1px solid #D1D1D1;
  }
  :deep(.el-switch__action) {
    background-color: #333333;
  }

  &.is-loading :deep(.el-switch__core) {
    cursor: pointer;
  }
}

.f-switch.is-big {
  :deep(.el-switch__core) {
    width: 40px !important;
    border-radius: 31px;
  }
  :deep(.el-switch__action) {
    width: 30px;
    height: 30px;
    top: calc(50% - 15px);
    left: -8px;
  }
  &.is-checked {
    :deep(.el-switch__action) {
      left: 100%;
    }
  }
}

.f-switch.is-small {
  :deep(.el-switch__core) {
    width: 32px !important;
    min-width: 32px;
    border-radius: 31px;
  }
  :deep(.el-switch__action) {
    width: 14px;
    height: 14px;
    top: calc(50% - 7px);
  }
}

.f-switch.primary {
  :deep(.el-switch__core) {
    background-color: var(--color-white) !important;
    border: 1px solid var(--color-D1D1D1);
  }
  :deep(.el-switch__action) {
    background-color: var(--color-C8C8C8);
  }
  &.is-checked {
    :deep(.el-switch__core) {
      background-color: var(--color-primary) !important;
      border: 1px solid var(--color-primary);
    }
    :deep(.el-switch__action) {
      background-color: var(--color-white);
    }
  }
  &.is-disabled {
    opacity: 1;
    :deep(.el-switch__core) {
      background-color: var(--color-black-005) !important;
      border: 1px solid transparent;
    }
    :deep(.el-switch__action) {
      background-color: var(--color-black-007);
    }
  }
}

.f-switch.success.is-checked {
  :deep(.el-switch__core) {
    background-color: var(--color-green-05);
    border: 1px solid var(--color-318C00);
  }

  :deep(.el-switch__action) {
    background-color: var(--color-green);
  }

  :deep(.is-loading svg path) {
    fill: var(--color-white);
  }
}

:deep(.is-loading) {
  color: var(--color-black);
}

.f-switch.danger {
  :deep(.el-switch__core) {
    background-color: var(--color-white);
    border: 1px solid #D1D1D1;
  }
  :deep(.el-switch__action) {
    background-color: #C8C8C8;
  }
  &.is-checked {
    :deep(.el-switch__core) {
      background-color: var(--color-error);
      border: 1px solid var(--color-error);
    }
    :deep(.el-switch__action) {
      background-color: var(--color-white);
    }
  }
}

.has-labels {
  :deep(.el-switch__core) {
    .el-switch__inner {
      left: -1px;
      z-index: 99;
      color: #fff;
      .is-text {
        font-size: 10px;
      }
    }
  }
  &.is-checked {
    :deep(.el-switch__core) {
      .el-switch__inner {
        left: calc(100% + 7px);
      }
    }
  }
}

.is-intermidiate {
  :deep(.el-switch__action) {
    background-color: transparent !important;
  }

  &::before {
    content: '';
    width: 10px;
    height: 2px;
    border-radius: 2px;
    background-color: var(--color-white);

    position: absolute;
    left: calc(50% - 5px);
    top: calc(50% - 1px);
    z-index: 9;
  }
}
</style>
